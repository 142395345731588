footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #f1f1f1;
    display: flex;
    justify-content: flex-end;
    padding: 10px;
  }
  
  footer button {
    padding: 5px 15px;
    background-color: #ff0000;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 14px;
    white-space: nowrap;
    margin-right: 25px;
    min-width: 100px;
  }
  